import { useCallback, useContext, useEffect, useRef, useState } from "react";
import "./messaging.scss";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
  MdAttachFile,
} from "react-icons/md";
import { HiMail } from "react-icons/hi";

import DEFAULTPIC from "../../media/images/default.jpg";
import ONLINE from "../../media/images/online.png";

import EMOJI from "../../media/icons/blush.png";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";

// Toast notifications
import { toast } from "sonner";

import messageSent from "../../media/alerts/message-sent.wav";
import messageReceived from "../../media/alerts/new-message.wav";
import { SocketContext } from "../../context/SocketContext";
import { makeRequest } from "../../axios";

const Messaging = () => {
  const { loggedInUser, authToken, userDetails, isRestricted } =
    useContext(AuthContext);
  const { socketConnect, onlineUsers, disconnectedUsers } =
    useContext(SocketContext);

  const sentAlert = new Audio(messageSent);

  const [openChat, setOpenChat] = useState(false);

  const toggleChatBody = () => {
    setOpenChat(!openChat);
  };

  const fetchChatList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/chats/getChatList/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return res.data;
    } catch (error) {
      // console.error("Error fetching chats:", error);
      throw new Error(error.message);
    }
  };

  const { data: chatList } = useQuery({
    queryKey: ["chatList", userDetails.userId],
    queryFn: fetchChatList,
  });

  // Current Chat
  const [currentChat, setCurrentChat] = useState(null);

  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState([]);
  const openConversation = async (user) => {

    // console.log(currentChat)
    if (isRestricted) {
      toast.error(
        "You can't perform this action because your account is under a 72 hour restriction. You will be notified once the restriction period is over.",
        { duration: 5000 }
      );
      return;
    }

    setCurrentChat(user);

    try {
      await makeRequest.put(
        "chats/setSeen",
        { chatId: currentChat?.chatId },
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const receivedAlert = new Audio(messageReceived);
    socketConnect?.on("getMessage", (data) => {
      // console.log("Message received: ", data);
      setArrivalMessage({
        sender: data.senderId,
        messageText: data.messageText,
        date: Date.now(),
      });
      receivedAlert.play();
    });
  }, [socketConnect]);

  useEffect(() => {
    if (arrivalMessage && arrivalMessage.sender === currentChat?.userId) {
      setMessages((prev) => [...prev, arrivalMessage]);
    }
  }, [arrivalMessage, currentChat]);

  // Make the screen always scroll to the bottom
  const scrollRef = useRef();
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Fetch mutual messages
  const fetchMessages = useCallback(async () => {
    if (!currentChat) return;
    // console.log(currentChat.chatId)

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/chats/getMutualMessages/${currentChat?.chatId}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setMessages(res.data);
      return res.data;
    } catch (error) {
      console.error("Error fetching chats:", error);
      throw new Error(error.message);
    }
  }, [currentChat, authToken]);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  const [messageText, setMessageText] = useState("");
  const resetForm = () => {
    setMessageText("");
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (isRestricted) {
      toast.error(
        "You can't perform this action because your account is under a 72 hour restriction. You will be notified once the restriction period is over",
        { duration: 5000 }
      );
      return;
    }

    sentAlert.play();

    socketConnect?.emit("sendMessage", {
      senderId: userDetails.userId,
      receiverId: currentChat.userId,
      messageText,
    });

    const messageContent = {
      messageText: messageText,
      senderFullname: userDetails?.fullName,
      senderUsername: userDetails?.username,
      senderId: userDetails?.userId,
      senderImg: userDetails?.userImage,
      receiverFullname: userDetails?.fullName,
      receiverUsername: userDetails?.username,
      receiverVerified: userDetails?.verified,
    };

    try {
      axios.defaults.withCredentials = true;
      await axios.post(
        `${process.env.REACT_APP_URL}/chats/sendMessage/${currentChat.userId}`,
        messageContent,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      fetchMessages();
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  // Show user's last seen
  const user = disconnectedUsers?.find(
    (user) => user.userId === currentChat?.userId
  );
  const lastSeen = user ? user.lastSeen : null;
  // console.log("lastSeen:", lastSeen);

  return (
    <div
      className="messaging"
      style={{
        height: openChat ? "calc(100vh - 9em)" : "calc(100vh - 34.8em)",
        transition: "0.5s ease",
      }}
    >
      <div className="messaging-header">
        <div className="messaging-header-left">
          <p>Messages</p>
        </div>
        <div className="messaging-header-right">
          <div className="notification">
            <HiMail className="icon" />
            {arrivalMessage?.length > 0 && (
              <div className="notification-count">{arrivalMessage.length}</div>
            )}
          </div>
          <div className="switch">
            {!openChat ? (
              <MdKeyboardDoubleArrowUp
                className="icon"
                onClick={toggleChatBody}
              />
            ) : (
              <MdKeyboardDoubleArrowDown
                className="icon"
                onClick={toggleChatBody}
              />
            )}
          </div>
        </div>
      </div>
      <div className="messaging-body">
        <div className="messaging-body-left">
          <div className="searchUser">
            <input type="text" placeholder="Search user" />
          </div>
          {chatList?.length > 0
            ? chatList.map((user, index) => (
                <div
                  className="useList"
                  key={index}
                  // onClick={() => setCurrentChat(user)}
                  onClick={() => openConversation(user)}
                >
                  <div className="useList-left">
                    {user.userImage ? (
                      <img
                        src={`${process.env.REACT_APP_URL}/images/users/profileImages/${user.userImage}`}
                        alt=""
                      />
                    ) : (
                      <img src={DEFAULTPIC} alt="" />
                    )}
                    {onlineUsers?.includes(user.userId) ? (
                      <div className="onlineStatus">
                        <img src={ONLINE} alt="onlineImg" />
                      </div>
                    ) : (
                      <div className="offlineStatus"></div>
                    )}
                  </div>
                  <div className="useList-right">
                    <div className="userName">
                      {user.fullName ? user.fullName : user.username}
                    </div>
                    <div className="lastMessage">
                      {user?.lastChatSender === loggedInUser.userId
                        ? "You:"
                        : ""}{" "}
                      {user.lastChatMessage?.length > 16
                        ? user.lastChatMessage?.slice(0, 16) + "..."
                        : user?.lastChatMessage}
                    </div>
                  </div>
                </div>
              ))
            : "You have no chat history with anyone!"}
        </div>
        <div className="messaging-body-right">
          {currentChat ? (
            <>
              <div className="incomingUser">
                <img
                  src={
                    currentChat?.userImage !== null
                      ? `${process.env.REACT_APP_URL}/images/users/profileImages/${currentChat?.userImage}`
                      : DEFAULTPIC
                  }
                  alt=""
                />
                <Link to={`/profile/${currentChat?.username}`} className="name">
                  {currentChat?.fullName
                    ? currentChat?.fullName
                    : currentChat?.username}
                  <p>
                    {onlineUsers.includes(currentChat.userId) ? (
                      <div className="online">Online</div>
                    ) : (
                      <div className="online">
                        {lastSeen
                          ? `Last seen: ${moment(lastSeen).fromNow()}`
                          : `Last seen: ${moment(
                              currentChat?.lastSeen
                            ).fromNow()}`}
                      </div>
                    )}
                  </p>
                </Link>
              </div>
              <div className="chat-screen">
                {messages?.length > 0 ? (
                  messages?.map((chatData, index) => (
                    <div
                      ref={scrollRef}
                      className={`msgs ${
                        chatData?.senderId === userDetails?.userId
                          ? "outgoing-msg"
                          : "incoming-msg"
                      }`}
                      key={index}
                    >
                      <div className="msg-details">
                        <p>{chatData?.chatMsg || chatData.messageText}</p>
                        <small>{moment(chatData?.date).format("LLL")}</small>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="startChat">
                    Send a message to start a conversation
                  </div>
                )}
              </div>
              <div className="inputForm">
                <form encType="multipart/form-data">
                  <div className="left">
                    <textarea
                      cols="30"
                      rows="1"
                      placeholder="Type a message..."
                      value={messageText}
                      onChange={(e) => setMessageText(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="right">
                    <label htmlFor="addFile">
                      <MdAttachFile className="icon" />
                    </label>
                    <input type="file" id="addFile" />
                    <img src={EMOJI} alt="" />
                    <button
                      onClick={sendMessage}
                      type="submit"
                      disabled={!messageText.trim()}
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <div className="emptyChat">
              <p>Click a user to open a conversation</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Messaging;
